class SeeOnYoutOwn {
  #availableShowtimes;

  bindEvents() {
    document
      .querySelector('#lockbox_showtime_date')
      .addEventListener('change', this.updateLockboxShowtimeTime.bind(this));
    document.querySelector('#lockbox_showtime_date').addEventListener('change', this.updateLockboxShowtime.bind(this));
    document.querySelector('#lockbox_showtime_time').addEventListener('change', this.updateLockboxShowtime.bind(this));
  }

  updateLockboxShowtime() {
    document.querySelector('#showing_lockbox_showtime').value =
      document.querySelector('#lockbox_showtime_date').value +
      ' ' +
      document.querySelector('#lockbox_showtime_time').value;
  }

  optionExists(val) {
    return document.querySelector('#mySelect option[value="' + val + '"]');
  }

  updateLockboxShowtimeTime() {
    document.querySelector('#lockbox_showtime_time').innerHTML = '';
    const current_date = document.querySelector('#lockbox_showtime_date').value;
    this.#availableShowtimes.forEach(function (time_array) {
      if (current_date == time_array[0]) {
        const opt = `<option value="${time_array[1].trim()}">${time_array[1].trim()}</option>`;
        document.querySelector('#lockbox_showtime_time').insertAdjacentHTML('beforeend', opt);
      }
    });
    // eslint-disable-next-line
    set_initial_time_lockbox_showtime_time();
  }

  init(wrapper) {
    const availableDates = JSON.parse(wrapper.dataset.availableDates);
    this.#availableShowtimes = JSON.parse(wrapper.dataset.availableShowtimes);

    availableDates.forEach(function (date_array) {
      const opt = `<option value="${date_array[1]}">${date_array[0]}</option>`;
      document.querySelector('#lockbox_showtime_date').insertAdjacentHTML('beforeend', opt);
    });

    const initialDate = document.querySelector('#showing_lockbox_showtime').value.split(' ')[0];
    const initialTime = document.querySelector('#showing_lockbox_showtime').value.split(' ').slice(1).join(' ').trim();
    if (initialDate != '') {
      document.querySelector('#lockbox_showtime_date').value = initialDate;
    }
    this.updateLockboxShowtimeTime();
    if (initialTime != '') {
      document.querySelector('#lockbox_showtime_time').value = initialTime;
    }
    this.updateLockboxShowtime();

    this.bindEvents();
  }
}

export default SeeOnYoutOwn;
