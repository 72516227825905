import DashboardUtils from '../utils/dashboard_utils';
import LoadShowtimes from './load_showtimes';
import ProspectShowtimes from './showtimes';
import {postMessageToSameOrigin} from '../utils/iframe_helper';

class ShowingsForm {
  #showingFormControlsInited;
  #wrapper;

  // eslint-disable-next-line  no-unused-vars
  isValidPhone(val) {
    if (!val) { val = document.querySelector('#showing_phone').value; }
    val = val.replace(/\D/g, '');
    if (!val.startsWith('1')) { val = '1' + val; }

    return (val.length == 11);
  }

  prepareShowingFormControls() {
    if (this.#showingFormControlsInited) { return; }
    this.#showingFormControlsInited = true;

    if (this.#wrapper.dataset.showtime) {
      const picker = document.querySelector('#available_showtimes');
      if (picker) {
        picker.dispatchEvent(new CustomEvent('showtime-picker:setShowtime', {detail: {showtime: this.#wrapper.dataset.showtime}}));
      }
    }

    const wrapper = this.#wrapper;
    document.querySelectorAll('form').forEach((form) => {
      form.addEventListener('submit', function() {
        if (document.querySelector('input[type="submit"]').getAttribute('disabled')) {
          return false;
        } else {
          document.querySelector('input[type="submit"]').setAttribute('disabled', 'disabled');
          if (JSON.parse(wrapper.dataset.lockbox)) {
            set_lockbox_when_showtime_not_selected();
          }
        }
      });
    });

    function set_lockbox_when_showtime_not_selected() {
      var lockbox_mode = (document.querySelector('#showing_on_lockbox').value == 'true');
      var showtime_selected = (document.querySelector('ul.hours.active .button.active')) || (document.querySelector('ul.showtime-list li.showtime.active'));
      if (!lockbox_mode && !showtime_selected) {
        document.querySelector('#showing_on_lockbox').value = 'true';
      }
    }

    function get_upcoming(){
      if (document.querySelector('#showing_email')?.value == document.querySelector('div#upcoming_data input#upcoming_email')?.value || document.querySelector('#showing_email')?.value == '') { return false; }

      let url = window.location.toString().replace(/\/$/, '')
        .replace(/\?.*/, '') + '/upcoming/' + document.querySelector('#showing_email')?.value + '/p/' + document.querySelector('#showing_phone').value;

      if (wrapper.dataset.listingUid) {
        url += `?listing_uid=${wrapper.dataset.listingUid}`;
      }

      fetch(url, {headers: {'Content-Type': 'application/json'}})
        .then(FetchUtils.checkResponseStatus)
        .then(response => response.json())
        .then(data => {
          if (data.prospect_has_verified_credit_card) {
            LayoutUtils.hide(document.querySelector('.question[data-type="verification_method"]')?.childNodes);
            LayoutUtils.hide(document.querySelector('.question[data-type="card"]')?.childNodes);
            document.querySelector('.question[data-type="card"]')?.insertAdjacentHTML('beforeend', '<p>Credit Card Previously Approved</p>');
          } else {
            LayoutUtils.show(document.querySelector('.question[data-type="verification_method"]')?.childNodes);
            LayoutUtils.show(document.querySelector('.question[data-type="card"]')?.childNodes);
            document.querySelector('.question[data-type="card"] p')?.remove();
          }
        })
        .catch(err => FetchUtils.handleResponseError(err));
    }

    if (this.#wrapper.dataset.embeddedPopup == '') {
      document.querySelectorAll('#showing_email, #showing_phone').forEach(el => el.addEventListener('change', get_upcoming));
      if(document.querySelector('#showing_email')?.value !== '' || document.querySelector('#showing_phone').value !== '') {
        get_upcoming();
      }
    }

    document.querySelector('#new_showing')?.addEventListener('submit', function(e) {
      const form = e.currentTarget;
      e.preventDefault();
      LayoutUtils.show(document.querySelector('.loader'));

      setTimeout(function() {
        form.submit();
      }, 100);
    });
  }

  bindEvents() {
    const wrapper = this.#wrapper;
    let _this = this;
    let phoneEmailChangeHandler = {
      debounce: function(type, mil) {
        var timer;
        var self = this;

        return function() {
          clearTimeout(timer);
          timer = setTimeout(function() {
            if (type == 'card') {
              self.cardVerificationCheck();
            } else if (type == 'showtimes') {
              self.refreshShowtimes();
            }
          }, mil);
        };
      },

      cardVerificationCheck: function() {
        // Only make a trip to server if both inputs are present
        if (document.querySelector('#showing_phone').value != '' && document.querySelector('#showing_email')?.value != '') {
          var email = document.querySelector('#showing_email')?.value,
            phone = document.querySelector('#showing_phone')?.value,
            account_id = wrapper.dataset.accountId;

            ProspectUtils.verifyCard(account_id, email, phone);
        }
      },

      refreshShowtimes: function() {
        if (JSON.parse(wrapper.dataset.allowDoubleBooking)) { return; }

        const phone = document.querySelector('#showing_phone')?.value;
        const email = document.querySelector('#showing_email')?.value;
        const previousPhone = document.querySelector('#showing_phone')?.dataset.previousValue;
        const previousEmail = document.querySelector('#showing_email')?.dataset.previousValue;
        const listingUid = wrapper.dataset.listingUid;
        const showingUid = wrapper.dataset.showingUid;
        const selectedShowtime = document.querySelector('#showtime').value;
        if (phone == '' || email == '') { return; }
        if (phone == previousPhone && email == previousEmail ) { return; }
        if (!_this.isValidPhone(phone) || !DashboardUtils.isValidEmail(email)) { return; }

        LayoutUtils.show(document.querySelector('.js-showtimes-loader'));
        LayoutUtils.hide(document.querySelector('.js-showtimes-table'));
        document.querySelector('#showing_phone').setAttribute('data-previous-value', phone);
        document.querySelector('#showing_email').setAttribute('data-previous-value', email);
        const params = {
          id: listingUid,
          'showing[uid]': showingUid,
          'showing[email]': email,
          'showing[phone]': phone,
          'showing[showtime]': selectedShowtime,
          force: true
        };
        fetch('/refresh_showtimes?' + new URLSearchParams(params), {headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }})
          .then(FetchUtils.checkResponseStatus)
          .then(response => response.json())
          .then(data => {
            if (!data.no_showtimes) {
              new ProspectShowtimes().init(data);
            }
          })
          .finally(() => {
            LayoutUtils.show(document.querySelector('.js-showtimes-table'));
            LayoutUtils.hide(document.querySelector('.js-showtimes-loader'));
          })
          .catch(err => FetchUtils.handleResponseError(err));
      }
    };

    if (!wrapper.classList.contains('js-create-prospects-showings')) {
      // Track and debounce keyup events to reduce the load on the server/DB
      document.querySelectorAll('#showing_phone, #showing_email').forEach(el => {
        el.addEventListener('keyup', () => {
          phoneEmailChangeHandler.debounce('card', 1000)();
          phoneEmailChangeHandler.debounce('showtimes', 2000)();
        });
      });
    }
  }

  loadShowtimes() {
    const listingUid = this.#wrapper.dataset.listingUid;
    const showingUid = this.#wrapper.dataset.showingUid;
    const embeddedPopup = this.#wrapper.dataset.embeddedPopup;
    const vParam = this.#wrapper.dataset.vParam;

    let data = {
      id: listingUid,
      "showing[uid]": showingUid,
      "showing[showtime]": document.querySelector('#showtime')?.value,
      showtime: document.querySelector('#shown-showtime')?.value
    };

    if (embeddedPopup) {
      data['embedded_popup'] = embeddedPopup;
    }

    if (vParam) {
      data['v'] = vParam;
    }

    if (listingUid) {
      data['listing_uid'] = listingUid;
    }

    data['async'] = true;

    fetch('/load_showtimes?' + new URLSearchParams(data), {headers: {
      'X-CSRF-Token': FetchUtils.CSRFToken(),
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=utf-8'
    }})
      .then(FetchUtils.checkResponseStatus)
      .then(response => response.json())
      .then(data => {
        new LoadShowtimes().init(data);
      })
      .catch(err => FetchUtils.handleResponseError(err));
  }

  init(wrapper) {
    this.#wrapper = wrapper;
    this.#showingFormControlsInited = false;
    this.bindEvents();

    function finishOnboarding() {
      var showingUid = wrapper.dataset.showingUid;
      postMessageToSameOrigin({ action: 'ONBOARDING_SHOWING_SCHEDULED', showing_uid: showingUid });
    }

    if (wrapper.classList.contains('js-create-prospects-showings')) {
      // Give a user 5 sec to read a message before redirecting
      setTimeout(finishOnboarding, this.#wrapper.dataset.redirectionTimeout);


    } else {
      if (!String.prototype.endsWith) {
        String.prototype.endsWith = function(suffix) {
          return this.indexOf(suffix, this.length - suffix.length) !== -1;
        };
      }

      if (document.querySelector('#showingModal')?.classList.contains('in')) {
        // If modal window is opened already, we simply init form controls
        this.prepareShowingFormControls();
      } else {
        // Otherwise, we add form controls initialization as a callback
        document.querySelector('#showingModal')?.addEventListener('shown.bs.modal', () => this.prepareShowingFormControls());
      }

      if (window.self !== window.top ) {
        this.prepareShowingFormControls();
      }

      postMessageToSameOrigin({ action: 'ONBOARDING_HEADER_LOADED' });
    }

    this.loadShowtimes(wrapper);
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const wrapper = document.querySelector('.js-prospects-showings');
  if (wrapper) {
    new ShowingsForm().init(wrapper);
  }
});
