class AlternateShowtimes {
  #askProspectTimezone;
  #availableShowtimes;

  optionExists(val, option_kind) {
    return document.querySelector('#showing_proposed_time_' + option_kind + ' option[value="' + val + '"]');
  }

  updateProposedTimes() {
    document.querySelector('#showing_proposed_times').value = this.#askProspectTimezone
      ? document.querySelector('.showtime_option.best .time select').value +
        ',' +
        document.querySelector('.showtime_option.second .time select').value +
        ',' +
        document.querySelector('.showtime_option.third .time select').value
      : document.querySelector('.showtime_option.best .date select').value +
        ' ' +
        document.querySelector('.showtime_option.best .time select').value +
        ',' +
        document.querySelector('.showtime_option.second .date select').value +
        ' ' +
        document.querySelector('.showtime_option.second .time select').value +
        ',' +
        document.querySelector('.showtime_option.third .date select').value +
        ' ' +
        document.querySelector('.showtime_option.third .time select').value;
  }

  updateShowtimeTimes1() {
    const prev_time = document.querySelector('#showing_proposed_time_best').value;
    document.querySelector('#showing_proposed_time_best').innerHTML = '';
    const current_date = document.querySelector('#showing_proposed_date_best').value;
    let opt;
    this.#availableShowtimes.forEach((time_array) => {
      if (this.#askProspectTimezone) {
        const value = time_array[0] + ' ' + time_array[1];
        if (current_date == time_array[2]) {
          opt = `<option value="${value}" ${prev_time == value ? 'selected' : ''}>${time_array[3]}</option>`;
          document.querySelector('#showing_proposed_time_best').insertAdjacentHTML('beforeend', opt);
        }
      } else {
        if (current_date == time_array[0]) {
          opt = `<option value="${time_array[1]}" ${prev_time == time_array[1] ? 'selected' : ''}>${time_array[1]}</option>`;
          document.querySelector('#showing_proposed_time_best').insertAdjacentHTML('beforeend', opt);
        }
      }
    });
  }

  updateShowtimeTimes2() {
    const prev_time = document.querySelector('#showing_proposed_time_second').value;
    document.querySelector('#showing_proposed_time_second').innerHTML = '';
    const current_date = document.querySelector('#showing_proposed_date_second').value;
    let opt;
    this.#availableShowtimes.forEach((time_array) => {
      if (this.#askProspectTimezone) {
        const value = time_array[0] + ' ' + time_array[1];
        if (current_date == time_array[2]) {
          opt = `<option value="${value}" ${prev_time == value ? 'selected' : ''}>${time_array[3]}</option>`;
          document.querySelector('#showing_proposed_time_second').insertAdjacentHTML('beforeend', opt);
        }
      } else {
        if (current_date == time_array[0]) {
          opt = `<option value="${time_array[1]}" ${prev_time == time_array[1] ? 'selected' : ''}>${time_array[1]}</option>`;
          document.querySelector('#showing_proposed_time_second').insertAdjacentHTML('beforeend', opt);
        }
      }
    });
  }

  updateShowtimeTimes3() {
    const proposedTimeThird = document.querySelector('#showing_proposed_time_third');
    if (proposedTimeThird) {
      const prev_time = proposedTimeThird.value;
      proposedTimeThird.innerHTML = '';
      const current_date = document.querySelector('#showing_proposed_date_third').value;
      let opt;
      this.#availableShowtimes.forEach((time_array) => {
        if (this.#askProspectTimezone) {
          const value = time_array[0] + ' ' + time_array[1];
          if (current_date == time_array[2]) {
            opt = `<option value="${value}" ${prev_time == value ? 'selected' : ''}>${time_array[3]}</option>`;
            proposedTimeThird.insertAdjacentHTML('beforeend', opt);
          }
        } else {
          if (current_date == time_array[0]) {
            opt = `<option value="${time_array[1]}" ${prev_time == time_array[1] ? 'selected' : ''}>${time_array[1]}</option>`;
            proposedTimeThird.insertAdjacentHTML('beforeend', opt);
          }
        }
      });
    }
  }

  bindEvents() {}

  init(wrapper) {
    this.#askProspectTimezone = JSON.parse(wrapper.dataset.askProspectTimezone);
    const availableDates = JSON.parse(wrapper.dataset.availableDates);
    this.#availableShowtimes = JSON.parse(wrapper.dataset.availableShowtimes);

    availableDates.forEach((date_array) => {
      const opt = `<option value=${date_array[1]}>${date_array[0]}</option>`;
      document.querySelector('#showing_proposed_date_best').insertAdjacentHTML('beforeend', opt);
      document.querySelector('#showing_proposed_date_second').insertAdjacentHTML('beforeend', opt);
      document.querySelector('#showing_proposed_date_third').insertAdjacentHTML('beforeend', opt);
    });

    const proposedTimesArr = document.querySelector('#showing_proposed_times').value.split(',');
    const initialDates = proposedTimesArr.map((val) => {
      return val.split(' ')[0].trim();
    });

    let initialTimes;
    if (wrapper.dataset.askProspectTimezone) {
      initialTimes = proposedTimesArr.map(function (val) {
        return val.split(' ').join(' ').trim();
      });
    } else {
      initialTimes = proposedTimesArr.map(function (val) {
        return val.split(' ').slice(1).join(' ').trim();
      });
    }

    if (initialDates.length > 1) {
      document.querySelector('#showing_proposed_date_best').value = initialDates[0];
      document.querySelector('#showing_proposed_date_second').value = initialDates[1];
      document.querySelector('#showing_proposed_date_third').value = initialDates[2];
    } else {
      if (availableDates.length > 0) {
        document.querySelector('#showing_proposed_date_best').value = availableDates[0][1];
        if (availableDates.length > 1) {
          document.querySelector('#showing_proposed_date_second').value = availableDates[1][1];
          if (availableDates.length > 2) {
            document.querySelector('#showing_proposed_date_third').value = availableDates[2][1];
          }
        }
      }
    }
    this.updateShowtimeTimes1();
    this.updateShowtimeTimes2();
    this.updateShowtimeTimes3();

    let opt;
    if (initialTimes.length > 1) {
      if (!this.optionExists(initialTimes[0], 'best')) {
        opt = `<option value=${initialTimes[0]}>${initialTimes[0]}</option>`;
        document.querySelector('#showing_proposed_time_best').insertAdjacentHTML('beforeend', opt);
      }
      if (!this.optionExists(initialTimes[1], 'second')) {
        opt = `<option value=${initialTimes[1]}>${initialTimes[1]}</option>`;
        document.querySelector('#showing_proposed_time_second').insertAdjacentHTML('beforeend', opt);
      }
      if (!this.optionExists(initialTimes[2], 'third')) {
        opt = `<option value=${initialTimes[2]}>${initialTimes[2]}</option>`;
        document.querySelector('#showing_proposed_time_third').insertAdjacentHTML('beforeend', opt);
      }
    }
    if (initialTimes[0]) {
      document.querySelector('#showing_proposed_time_best').value = initialTimes[0];
    }
    if (initialTimes[1]) {
      document.querySelector('#showing_proposed_time_second').value = initialTimes[1];
    }
    if (initialTimes[2]) {
      document.querySelector('#showing_proposed_time_third').value = initialTimes[2];
    }

    this.updateProposedTimes();
    document
      .querySelector('#showing_proposed_date_best')
      .addEventListener('change', this.updateShowtimeTimes1.bind(this));
    document
      .querySelector('#showing_proposed_date_second')
      .addEventListener('change', this.updateShowtimeTimes2.bind(this));
    document
      .querySelector('#showing_proposed_date_third')
      .addEventListener('change', this.updateShowtimeTimes3.bind(this));
    document
      .querySelector('.showtime_option .date select')
      .addEventListener('change', this.updateProposedTimes.bind(this));
    document
      .querySelector('.showtime_option .time select')
      .addEventListener('change', this.updateProposedTimes.bind(this));

    this.bindEvents();
  }
}

export default AlternateShowtimes;
