import DashboardUtils from '../../utils/dashboard_utils';

class TimePicker {
  #dataContainer;
  #customShowtimeTimeout;

  checkAcceptance() {
    if (document.querySelector('#accept_box').getAttribute('data-accept-enable') === 'true') {
      const old_showtime = document.querySelector('#accept_box').getAttribute('data-old-showtime');
      const old_showtime_formatted = document.querySelector('#accept_box').getAttribute('data-old-showtime-formatted');
      const old_accepted = document.querySelector('#accept_box').getAttribute('data-old-accepted') === 'true';
      const new_showtime = document.querySelector('#showing_showtime').value;

      if (new_showtime == old_showtime || new_showtime == old_showtime_formatted) {
        if (old_accepted) {
          document.querySelector('#showing_accepted').getAttribute('checked', 'checked');
          LayoutUtils.hide(document.querySelector('#accept_box'));
        }

        return;
      }

      document.querySelector('#showing_accepted').removeAttribute('checked');
      LayoutUtils.show(document.querySelector('#accept_box'));
    }
  }

  showOrHideLockboxIcon() {
    // in this case icon is shown whenever lockbox showtime is set
    // indicated with showing_on_lockbox setting
    if(document.querySelector('#showing_on_lockbox').value == 'true' && document.querySelector('#shown-showtime').value != '') {
      document.querySelector('#lockbox_icon').style.display = 'inline-block';
    } else {
      document.querySelector('#lockbox_icon').style.display = 'none';
    }
  }

  getParam(name) {
    return this.#dataContainer.dataset[name];
  }

  getBoolParam(name) {
    return this.#dataContainer.dataset[name] != '' ? JSON.parse(this.#dataContainer.dataset[name]) : false;
  }

  init() {
    this.#dataContainer = document.querySelector('.js-showtime-data');
    this.showOrHideLockboxIcon();

    const picker = document.querySelector('#available_showtimes');
    TimepickerBuilder.init(picker);
    if (this.getBoolParam('showtimeValuePresent') && !this.getBoolParam('showingOnLockbox')) {
      let value = document.querySelector('#shown-showtime').value || this.getParam('showtimeValue');
      if (picker) {
        picker.dispatchEvent(new CustomEvent('showtime-picker:setShowtime', {detail: {showtime: value}}));
      }
    }

    this.bindEvents(picker);

    document.querySelector('#showtime_type').dispatchEvent(new Event('change'));

    if (this.getBoolParam('disableShowtimeValidation')) {
      document.querySelector('#showing_disable_showtime_validation')?.click();
    }

    const customDateInput = document.querySelector('#custom_showtime_date');
    if (customDateInput) {
      flatpickr('#custom_showtime_date', {dateFormat: 'M j, Y', defaultDate: customDateInput.dataset.defaultDate || 'today'});
    }
  }

  changeShowTimeDelayed() {
    clearTimeout(this.#customShowtimeTimeout);
    document.querySelector('#showing_submit').setAttribute('disabled', 'disabled');
    this.#customShowtimeTimeout = setTimeout(() => {
      this.changeShowTime();
    }, 700);
  }

  bindEvents(picker) {
    const showingOnLockbox = document.querySelector('#showing_on_lockbox');
    showingOnLockbox?.addEventListener('change', () => {
      this.showOrHideLockboxIcon();
    });

    if (this.getBoolParam('isLockboxPresent')) {
      document.querySelector('#shown-showtime')?.addEventListener('change', this.showOrHideLockboxIcon);
    }

    if (picker) {
      picker.addEventListener('showtime:selected', (event) => {
        const {showtime, showtimeDisplay, surgeShowtime} = event.detail;
        document.querySelector('#showing_showtime').value = showtime;
        document.querySelector('#shown-showtime').value = showtimeDisplay;
        document.querySelector('.showtime-info')?.classList.remove('hidden-important');
        document.querySelector('#showing_surge_showtime').value = surgeShowtime;
        const isCustom = document.querySelector('#showtime_is_custom');
        if (isCustom) {
          isCustom.value = 0;
        }
        if (showingOnLockbox) {
          showingOnLockbox.value = '';
          showingOnLockbox.dispatchEvent(new Event('change'));
        }
        if (showtimeDisplay.endsWith(this.getParam('nowShowtimeLabel'))) {
          document.querySelector('#showing_lockbox_showtime').value = showtimeDisplay;
        } else {
          document.querySelector('#showing_lockbox_showtime').value = '';
        }
      });

      picker.addEventListener('showtime:selected', () => {this.selectShowTime();});
    }

    document.querySelector('#showing_disable_showtime_validation')?.addEventListener('change', function(e) {
      let showtimeValidationDisabled = e.target.checked;
      LayoutUtils.toggle(document.querySelectorAll('#notify_prospect_box, #confirmation_wrapper'), !showtimeValidationDisabled);
      if (showtimeValidationDisabled) {
        document.querySelector('#do_not_confirm')?.click();
      }
    });

    document.querySelector('#showtime_type')?.addEventListener('change', (e) => {
      const selectedOption = e.target.options[e.target.selectedIndex];
      if (!selectedOption) {
        return false;
      }

      const availableShowtimes = document.querySelector('#available_showtimes');
      const doNotConfirm = document.querySelector('#do_not_confirm');
      const lockboxShowtimes = document.querySelector('#lockbox_showtimes');
      const customShowtimes = document.querySelector('#custom_showtime');
      if(selectedOption.value === 'available') {
        document.querySelector('#showing_submit').removeAttribute('disabled');
        if (customShowtimes) {
          customShowtimes.style.display = 'none';
        }
        if (lockboxShowtimes) {
          lockboxShowtimes.style.display = 'none';
        }
        if (availableShowtimes) {
          availableShowtimes.style.display = 'block';
        }

        if (!this.getBoolParam('isLockboxPresent')) {
          DashboardUtils.hideLockboxOnlyElements();
        }
        if (document.querySelector('#showing_disable_showtime_validation')?.checked) {
          document.querySelector('#showing_disable_showtime_validation')?.click();
        }
        if (doNotConfirm) {
          doNotConfirm.style.display = 'block';
        }
      } else if(selectedOption.value === 'custom') {
        if (customShowtimes) {
          customShowtimes.style.display = 'block';
        }
        if (doNotConfirm) {
          doNotConfirm.style.display = 'block';
        }
        if (availableShowtimes) {
          availableShowtimes.style.display = 'none';
        }
        if (lockboxShowtimes) {
          lockboxShowtimes.style.display = 'none';
        }
        document.querySelector('.time_wrap .active')?.classList.remove('active');
        this.changeShowTime();
        if (!this.getBoolParam('isLockboxPresent')) {
          DashboardUtils.hideLockboxOnlyElements();
        }
      } else if(selectedOption.value === 'lockbox') {
        if (lockboxShowtimes) {
          lockboxShowtimes.style.display = 'block';
        }
        if (customShowtimes) {
          customShowtimes.style.display = 'none';
        }
        if (availableShowtimes) {
          availableShowtimes.style.display = 'none';
        }
        document.querySelector('.time_wrap .active')?.classList.remove('active');
        if (doNotConfirm) {
          doNotConfirm.style.display = 'block';
          if (doNotConfirm.classList.contains('clicked')) {
            document.querySelectorAll('.confirmation_type')[0].click();
          }
        }

        DashboardUtils.showLockboxOnlyElements();
      }

      document.dispatchEvent(new CustomEvent('sm-modal:update-height'));
    });

    document.querySelectorAll('#custom_showtime_date, #custom_showtime_duration').forEach((el) => {
      el.addEventListener('keyup', () => {this.changeShowTimeDelayed();});
      el.addEventListener('change', () => {this.changeShowTimeDelayed();});
    });

    document.querySelector('#custom_showtime_time')?.addEventListener('focusin', function() {
      document.querySelector('#showing_submit').setAttribute('disabled', 'disabled');
    });
    document.querySelector('#custom_showtime_time')?.addEventListener('focusout',() => {this.changeShowTimeDelayed();});
  }

  selectShowTime() {
    this.checkAcceptance();
    DashboardUtils.toggleEmailSchedule();
  }

  changeShowTime() {
    var timeFormat = /^(| )+\d{1,2}(:\d{2}|)(| )+(AM|PM|)$/i;
    if (document.querySelector('#custom_showtime_date')?.value &&
      document.querySelector('#custom_showtime_time')?.value.match(timeFormat)) {
      document.querySelector('#custom_showtime_time')?.classList.remove('show_time_error');
      this.selectShowTime();
      this.checkShowtime();
    } else {
      document.querySelector('#showing_submit').removeAttribute('disabled');
      if (document.querySelector('#custom_showtime_time')?.value !== undefined
        && document.querySelector('#custom_showtime_time')?.value.length != 0)  {
        document.querySelector('#custom_showtime_time')?.classList.add('show_time_error');
      }
    }
  }

  checkShowtime(){
    const isCustom = document.querySelector('#showtime_is_custom');
    if (isCustom) {
      isCustom.value = 1;
    }
    const showingOnLockbox = document.querySelector('#showing_on_lockbox');
    if (showingOnLockbox) {
      showingOnLockbox.value = '';
      showingOnLockbox.dispatchEvent(new Event('change'));
    }
    document.querySelector('#showing_lockbox_showtime').value = '';
    if (!this.getBoolParam('isLockboxPresent')) {
      DashboardUtils.hideLockboxOnlyElements();
    }

    const url = '/check_showtime';

    const params = {
      listing_id: document.querySelector('#showing_listing_id').value,
      date: document.querySelector('#custom_showtime_date')?.value,
      time: document.querySelector('#custom_showtime_time')?.value,
      duration: document.querySelector('#custom_showtime_duration')?.value
    };

    fetch(url, {method: 'POST', headers: {
      'X-CSRF-Token': FetchUtils.CSRFToken(),
      'Content-Type': 'application/json; charset=utf-8'
    }, body: JSON.stringify(params)}).then(FetchUtils.checkResponseStatus)
    .then((resp) => {
      return resp.json();
    }).then(json => {
      DashboardUtils.checkShowtime(json);
      document.querySelector('#showing_form').dispatchEvent(new CustomEvent('ajax:complete'));
    }).catch((err) => {
      FetchUtils.handleResponseError(err);
    });
  }
}

export default TimePicker;
