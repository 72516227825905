class ProspectVerificationMethod {
  hideCreditCardQuestion() {
    LayoutUtils.hide(document.querySelector('.question[data-type=card]'));
    document
      .querySelector('.question[data-type=card]')
      ?.querySelectorAll('input, textarea, select')
      .forEach((el) => el.setAttribute('disabled', true));
  }

  showCreditCardQuestion () {
    LayoutUtils.show(document.querySelector('.question[data-type=card]'));
    document
      .querySelector('.question[data-type=card]')
      ?.querySelectorAll('input, textarea, select')
      .forEach((el) => el.removeAttribute('disabled'));
  }

  hideDocumentVerificationQuestion () {
    LayoutUtils.hide(document.querySelector('.question[data-type=document_verification]'));
    document
      .querySelector('.question[data-type=document_verification]')
      ?.querySelectorAll('input, textarea, select')
      .forEach((el) => el.setAttribute('disabled', true));
  }

  showDocumentVerificationQuestion() {
    LayoutUtils.show(document.querySelector('.question[data-type=document_verification]'));
    document
      .querySelector('.question[data-type=document_verification]')
      ?.querySelectorAll('input, textarea, select')
      .forEach((el) => el.removeAttribute('disabled'));
  }

  render() {
    if (!this.el) {
      return;
    }
    const method = this.el.value;

    this.hideCreditCardQuestion();
    this.hideDocumentVerificationQuestion();

    if (method == this.el.dataset.methodCard) {
      this.showCreditCardQuestion();
    }

    if (method == this.el.dataset.methodDocument) {
      this.showDocumentVerificationQuestion();
    }
  }

  init() {
    this.el = document.querySelector('.question[data-type=verification_method] .verification_method');
    if (!this.el) {
      return;
    }

    let self = this;

    this.el.addEventListener('change', function () {
      self.render();
    });

    self.render();
  }
}

// Make this class global until all js will be replaced to javascripts folder
window.ProspectVerificationMethod = ProspectVerificationMethod;

document.addEventListener('DOMContentLoaded', function () {
  new ProspectVerificationMethod().init();
});
