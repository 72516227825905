class ProspectShowtimes {
  bindEvents(data) {
    document.querySelector('#available_showtimes')?.addEventListener('showtime:selected', function (event) {
      const { showtime, showtimeDisplay, surgeShowtime } = event.detail;
      document.querySelector('#showtime').value = showtime;
      document.querySelector('#shown-showtime').value = showtimeDisplay;
      document.querySelector('#surge_showtime').value = surgeShowtime;

      if (showtimeDisplay.endsWith(data.now_showtime_label)) {
        document.querySelector('#calendar_lockbox_showtime').value = showtimeDisplay;
      } else {
        document.querySelector('#calendar_lockbox_showtime').value = '';
      }
    });
  }

  init(data) {
    const alternateShowtimes = document.querySelector('#alternate_showtimes');
    if (alternateShowtimes) {
      const alternate_showtimes_status = document.querySelector('#alternate_showtimes').style.display;
      LayoutUtils.replaceWith(alternateShowtimes, data.alternate_showtimes);
      const newAlternateShowtimes = document.querySelector('#alternate_showtimes');
      newAlternateShowtimes.style.display = alternate_showtimes_status;
    }

    const availableShowtimes = document.querySelector('#available_showtimes');
    if (availableShowtimes) {
      const available_showtimes_status = availableShowtimes.style.display;
      LayoutUtils.replaceWith(availableShowtimes, data.available_showtimes);
      const newAvailableShowtimes = document.querySelector('#available_showtimes');
      newAvailableShowtimes.style.display = available_showtimes_status;
      TimepickerBuilder.init(newAvailableShowtimes);
      if (data.showtime) {
        newAvailableShowtimes.dispatchEvent(
          new CustomEvent('showtime-picker:setShowtime', { detail: { showtime: data.showtime } }),
        );
      }
    }

    const sameAgentNotificationField = document.querySelector('#same_agent_notification');
    if (data.same_agent && sameAgentNotificationField) {
      sameAgentNotificationField.innerHTML = data.same_agent_message;
      LayoutUtils.show(sameAgentNotificationField);
    } else {
      sameAgentNotificationField.innerHTML = '';
      LayoutUtils.hide(sameAgentNotificationField);
    }

    this.bindEvents(data);
  }
}

export default ProspectShowtimes;
